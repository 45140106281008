import { useTranslation } from 'next-i18next'
// Dependencies.
import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import { H1 } from '../H1'
import { CTA } from '../CTA'
import { AuthenticateWithGoogleButton } from '../AuthenticateWithGoogleButton'
import { AuthenticateWithFacebookButton } from '../AuthenticateWithFacebookButton'
import { FormField } from '../Inputs/FormField'
import { P } from '../P'
import { Hyperlink } from '../Hyperlink'
import { UserContext } from '../../context'
import { Input } from '../Inputs/Input'
import { stringFromQuery, colors } from '../../utils'
import { ValidationWrapper } from '../Inputs/ValidationWrapper'
import { useAnalyticsEvent } from '../../hooks/analyticsEvent'
import { PasswordInput } from '../Inputs/PasswordInput'

// Div.
const Div = styled.div`
  margin: auto;
  max-width: 90%;
  width: 493px;

  .header {
    margin-bottom: 37px;
  }

  .formField:last-child {
    margin-bottom: 15px;
  }

  .emailField {
    margin-bottom: 30px;
  }

  .forgotPassword {
    margin-bottom: 30px;
  }

  .submitButton {
    margin-bottom: 20px;
  }

  .AuthenticateWithGoogleButton {
    margin-bottom: 20px;
  }

  .AuthenticateWithFacebookButton {
    margin-bottom: 20px;
  }

  .logInCTA {
    text-align: center;
  }

  .forgotPassword {
    margin-top: 15px;
    text-align: right;
  }

  .forgotPassword .hyperlink {
    color: #a6a6a6;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 140%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .errorContainer {
    margin: auto;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .errorMessage {
    color: ${colors.red};
    text-align: center;
  }
`

// Props.
interface Props {}

// Sign Up Form.
export const LogInForm: React.FC<Props> = (props) => {
  const { t } = useTranslation('common')

  // Router.
  const router = useRouter()
  const queryAsString = stringFromQuery(router.query)

  // Context.
  const { logIn, loginWithGoogle, loginWithFacebook } = useContext(UserContext)

  const analyticsEvent = useAnalyticsEvent()

  // State.
  const [error, setError] = useState([])
  const [socialError, setSocialError] = useState([])
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')

  const fieldsAreFilled = email && password
  const submitButtonShouldBeEnabled = !loading

  const socialUserAlreadyExistedError = t(
    'Account is already registered with this email using method of email and password',
    'Account is already registered with this email using method of email and password',
  )
  // On Submit.
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      resetError()
      setLoading(true)
      await logIn({ email, password })
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError([
          t('Invalid email or password.', 'Invalid email or password.'),
        ])
      } else {
        setError(['Unknown error'])
        throw error
      }
      setLoading(false)
    }
  }

  const onGoogleAuthSuccess = (token: string) => {
    loginWithGoogle({ access_token: token })
      .then(() => {})
      .catch((error) => {
        const message = error['non_field_errors']
        if (message) {
          setSocialError([socialUserAlreadyExistedError])
        }
      })
  }

  const onFacebookAuthSuccess = (token: string) => {
    loginWithFacebook({ access_token: token })
      .then(() => {})
      .catch((error) => {
        const message = error['non_field_errors']
        if (message) {
          setSocialError([socialUserAlreadyExistedError])
        }
      })
  }

  const resetError = () => {
    setError([])
    setSocialError([])
  }

  // ..
  return (
    <Div>
      {/* Header. */}
      <H1 className="header">{t(`Log in`, `Log in`)}</H1>

      {/* Form. */}
      <form className="signUpForm" onSubmit={onSubmit} data-cy="loginForm">
        <div>
          {/* Email. */}
          <FormField
            className="emailField"
            required
            title={t(`Email`, `Email`)}>
            <Input
              onChange={(e) => {
                if (error) setError([])
                setEmail(e.target.value)
              }}
              placeholder={t(`Email`, `Email`)}
              type="email"
              value={email}
              data-cy="email"
            />
          </FormField>

          {/* Password. */}
          <ValidationWrapper error={error.length > 0 ? error[0] : ''}>
            <FormField required title={t(`Password`, `Password`)}>
              <PasswordInput
                onChange={(e) => {
                  if (error) setError([])
                  setPassword(e.target.value)
                }}
                placeholder={t(`Password`, `Password`)}
                value={password}
                data-cy="password"
              />
            </FormField>
          </ValidationWrapper>

          {/* Forgot Password. */}
          <div className="forgotPassword">
            <Hyperlink href="/password/forgot">
              {t(`Forgot password?`, `Forgot password?`)}
            </Hyperlink>
          </div>
        </div>

        {/* Forgot Password. */}
        <div className="forgotPassword" />

        {/* Submit Button. */}
        <CTA
          className="fullWidth purple submitButton"
          data-cy="loginButton"
          disabled={!submitButtonShouldBeEnabled}>
          {t(`Log in`, `Log in`)}
        </CTA>
      </form>

      {/* Google. */}
      <AuthenticateWithGoogleButton
        onAuthSuccess={onGoogleAuthSuccess}
        onAuthError={(error) => {
          setSocialError([error])
        }}
        onButtonClicked={() => {
          resetError()
        }}
        buttonText={t(`Log in using Google`, `Log in using Google`)}
      />
      <AuthenticateWithFacebookButton
        onAuthSuccess={onFacebookAuthSuccess}
        onAuthError={(error) => {
          setSocialError([error])
        }}
        onButtonClicked={() => {
          resetError()
        }}
        buttonText={t(`LogInForm823538017`, `Log in using Facebook`)}
      />
      {socialError.length > 0 && (
        <div className="errorContainer">
          {socialError.map((error, index) => {
            return (
              <P key={index} className="detail errorMessage">
                {error}
              </P>
            )
          })}
        </div>
      )}

      {/* Log In CTA. */}
      <P className="callout logInCTA">
        {t(`LogInForm-863605799`, `Don't have an account?`)}{' '}
        <Hyperlink
          href={`/signup${queryAsString}`}
          onClick={() => {
            analyticsEvent.signupLinkClicked('login-form')
          }}>
          {t(`Sign up`, `Sign up`)}
        </Hyperlink>
      </P>
    </Div>
  )
}
