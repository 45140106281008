import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
// Dependencies.
import styled from 'styled-components'

import { Page as PAGE } from '../components/Page'
import { LogInForm } from '../components/Forms/LogInForm'
import { useRedirectIfAuthenticated } from '../hooks/redirect'

// Page.
const Page = styled(PAGE)`
  padding-top: 100px;
  padding-bottom: 186px;
  font-weight: 600;
`

export const LoginPage = () => {
  useRedirectIfAuthenticated()

  return (
    <Page>
      <LogInForm />
    </Page>
  )
}
export async function getStaticProps({ locale }) {
  const translations = await serverSideTranslations(locale, ['common'])
  return {
    props: {
      ...translations,
    },
  }
}

export default LoginPage
