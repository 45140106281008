// Dependencies.
import styled from 'styled-components'

import { P } from '../P'
import { colors } from '../../utils'

// Div.
const Div = styled.div`
  .error {
    color: ${colors.red};
    margin-top: 8px;
  }
`

// Props.
interface Props {
  error?: string
}

// Input Validation Wrapper.
export const ValidationWrapper: React.FC<Props> = ({ children, error }) => {
  // ..
  return (
    <Div>
      {children}
      <P className="detail error">{error}</P>
    </Div>
  )
}
